'use client'

import { Header } from "@/components/header";
import { SidebarDesktop } from "@/components/sidebar-desktop";
import { ChatContextProvider } from "@/lib/chat/chat-state";

export default function RootPageLayout({children}: { children: React.ReactNode }) {
	return (
		<ChatContextProvider>
			<Header />
			<main className="flex flex-col flex-1 bg-muted/50">
			<div className="relative flex h-[calc(100vh_-_theme(spacing.16))] overflow-hidden">
				<SidebarDesktop />
				{children}
			</div>
			</main>
		</ChatContextProvider>
	);
}