import { createContext, Dispatch, useContext, useEffect } from 'react';
import { Draft } from 'immer';
import { useImmerReducer } from 'use-immer';
import { nanoid } from 'nanoid';
import { Chat, ChatMessage } from '../types';

export interface ChatContextData {
	chatId: string;
	chats: Chat[];
}
export interface ChatContextAction {
	type: 'addMsg' | 'resetMsgs' | 'newChat' | 'switchChat';
	chatId?: string;
	message?: ChatMessage;
}

export const initialChatContext: ChatContextData = {chatId: '', chats: []};
export const ChatContext = createContext<ChatContextData | undefined>(undefined);
export const ChatDispatchContext = createContext<Dispatch<ChatContextAction> | undefined>(undefined);

function chatContextReducer(draft: Draft<ChatContextData>, action: ChatContextAction) {
	const chatId = draft.chatId; // accessing draft is slow, it's a proxy
	let chat = chatId !== '' ? draft.chats.find(c => chatId == c.id) : null;
	switch (action.type) {
	case 'resetMsgs':
		if(!chat) {
			console.error(`Failed to find chat with id ${draft.chatId}`);
			return draft;
		}
		chat.messages = [];
		return draft;
	case 'addMsg':
		if(!action.message) {
			console.error('You need to send a message with the addMsg action');
			return draft;
		}
		if(!chat) {
			// There is no chat yet, create one
			const chatId = nanoid();
			const createdAt = new Date()
			// const userId = session.user.id as string
			const path = `/chat/${chatId}`
			chat = {
				id: chatId,
				title: '',
				userId: '1',
				createdAt,
				messages: [],
				path
			}
			draft.chatId = chatId; // Activate the current chat
			draft.chats.push(chat);
		}
		if(chat.title === '') {
			const firstMessageContent = action.message.transcription
			chat.title = firstMessageContent.substring(0, 100)
		}
		chat.messages.push(action.message);
		return draft;
	}
}
export const useChatContext = () => {
	const context = useContext(ChatContext);
	if (context === undefined)
		throw new Error('useChatContext must be used within a ChatContextProvider');
	return context;
}
export const useChatDispatchContext = () => {
	const context = useContext(ChatDispatchContext);
	if (context === undefined)
		throw new Error('useChatDispatchContext must be used within a ChatContextProvider');
	return context;
}
export function ChatContextProvider({ children }: {children : any}) {
	const onClient = typeof window !== 'undefined';
	const storageKey = 'chatContext';
	var initVal = initialChatContext;
	if(onClient) {
		const storedVal = localStorage.getItem(storageKey);
		initVal = storedVal ? JSON.parse(storedVal) : initialChatContext;
	}
	const [chatContext, chatContextDispatch] = useImmerReducer<ChatContextData, ChatContextAction>(chatContextReducer, initialChatContext);
	useEffect(() => { // Runs after every state change
		onClient && window.localStorage.setItem(storageKey, JSON.stringify(chatContext));
	}, [chatContext]);
	return (
		<ChatContext.Provider value={chatContext}>
		<ChatDispatchContext.Provider value={chatContextDispatch}>
			{children}
		</ChatDispatchContext.Provider>
		</ChatContext.Provider>
	);
}